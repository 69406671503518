import React from "react";

function Card({ children, className = "" }) {
  const combinedClassName = `bg-white shadow-md sm:rounded-lg p-5 sm:p-10 h-full mx-auto my-0 sm:my-10  ${className}`;

  return (
    <div className={combinedClassName}>
    {children}
  </div>
  );
}

export default Card;
