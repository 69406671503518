import React from "react";

export default function Terms() {
  return (
    <div className="flex items-center justify-between mt-4">
      <div className="flex items-center">
        <input
          id="terms"
          name="terms"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label htmlFor="terms" className="ml-3 block text-sm leading-6 text-gray-900">
          Eu li e aceito os  <a href="/terms" className="font-semibold text-indigo-600 hover:text-indigo-500">
          termos de uso!
        </a>
        </label>
      </div>
    </div>
  );
}
