import React, { useState } from "react";
import ButtonCustom from "../../components/button/Button.jsx";
import Card from "../../components/card/Card";
import Header from "../../components/header/header.jsx";
import SquareInput from "../../components/input/input.jsx";
import Terms from "../../components/terms/terms.jsx";
import ErrorDialog from "../../components/dialogs/error.jsx";
import SucessDialog from "../../components/dialogs/sucess.jsx";

function Home() {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setEmailError("Por favor, insira um endereço de e-mail válido.");
      return false;
    } else {
      setEmailError('');
      return true;
    }
  };

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);
    if (match) {
      return (!match[2] ? match[1] : '(' + match[1] + ') ' + match[2].substring(0, 5) + (match[3] ? '-' + match[3].substring(0, 4) : ''));
    }
    return null;
  }

  const handleEmailChange = (email) => {
    setEmail(email);
    validateEmail(email);
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
    if (!/(?=.*[A-Z])(?=.*\d).{6,}/.test(password)) {
      setPasswordError("A senha deve conter pelo menos 6 caracteres, incluindo 1 letra maiúscula e 1 número.");
      setPasswordSuccess('');
    } else {
      setPasswordError('');
      setPasswordSuccess('Senha forte!');
    }
  };

  const handleCreateAccount = async (event) => {
    event.preventDefault();
    if (name && email && phone && password && !passwordError && validateEmail(email)) {
      const requestBody = {
        name,
        email,
        phone,
        password,
      };
      console.log("Request Body:", requestBody);

      try {
        const response = await fetch("https://integracoes-topdesk-e715a5a4401b.herokuapp.com/user-registration/new", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Cadastro realizado com sucesso:", data);
          setShowSuccess(true);
        } else {
          console.error("Erro ao cadastrar:", response.statusText);
          setShowError(true);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
        setShowError(true);
      }
    } else {
      console.log("Por favor, preencha todos os campos.");
    }
  };

  const handleCloseErrorDialog = () => {
    setShowError(false);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccess(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      {showError && <ErrorDialog onClose={handleCloseErrorDialog} />}
      {showSuccess && <SucessDialog onClose={handleCloseSuccessDialog} />}
      <form className="w-full max-w-lg" onSubmit={handleCreateAccount}>
        <Header />
        <Card className="p-8">
          <div className="flex justify-between items-center w-full">
            <h1 className="text-2xl font-bold">Cadastre-se</h1>
            <a href="https://atendimento.rnp.br/tas/public/login/form" className="font-semibold text-indigo-600 hover:text-indigo-500">
              Já tem uma conta?
            </a>
          </div>
          <div className="mb-5 w-20 h-0.5 bg-[#000A8C]"></div>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
            <SquareInput type="text" placeholder=" " value={name} onChange={(e) => setName(e.target.value)} required>
              Nome Completo:
            </SquareInput>
            <SquareInput type="email" placeholder="" value={email} onChange={(e) => handleEmailChange(e.target.value)} required>
              E-mail:
            </SquareInput>
            {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
            <SquareInput 
  type="text" 
  placeholder="" 
  value={formatPhoneNumber(phone)} 
  onChange={(e) => {
    const inputPhone = e.target.value;
    const cleaned = ('' + inputPhone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);
    if (match) {
      const formatted = (!match[2] ? match[1] : match[1] + match[2].substring(0, 5) + (match[3] ? match[3].substring(0, 4) : ''));
      setPhone(formatted);
    }
  }} 
  maxLength={15} 
  required
>
  Celular:
</SquareInput>
            <SquareInput type="password" placeholder="" value={password} onChange={(e) => handlePasswordChange(e.target.value)} required>
              Senha:
            </SquareInput>
            {passwordError && <p className="text-red-500 text-sm mt-1">{passwordError}</p>}
            {passwordSuccess && <p className="text-green-500 text-sm mt-1">{passwordSuccess}</p>}
          </div>
          <Terms />
          <div className="flex flex-row-reverse mt-4">
            <ButtonCustom type="submit">
              Criar conta
            </ButtonCustom>
          </div>
        </Card>
      </form>
    </div>
  );
}

export default Home;
