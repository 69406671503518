import React from "react";

export default function ButtonCustom({ children, type = "button", onClick }) {
  return (
    <button
      type={type}
      onClick={onClick}
      className="inline-flex mt-4 items-center gap-x-1.5 rounded-md bg-[#000A8C] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#001EFF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {children}
    </button>
  );
}
