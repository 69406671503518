import React from "react";
import Card from "../../components/card/Card";
import ButtonCustom from "../../components/button/Button";

function Terms() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <Card className="p-8 max-w-xl">
        <div className="max-h-[70vh] overflow-y-auto">
          <h1>Termos e condições gerais de uso da plataforma</h1>
          <br/>
          <p>
            Os serviços da RNP são fornecidos pela pessoa jurídica com a
            seguinte Razão Social: Rede Nacional De Ensino E Pesquisa - Rnp, com
            nome fantasia RNP, inscrito no CNPJ sob o nº: 03.508.097/0003-06,
            titular da propriedade intelectual sobre software, website,
            aplicativos, conteúdos e demais ativos relacionados à plataforma
            Atendimento RNP.
          </p>
          <h2>1. Do objeto</h2>
          <p>
            A plataforma visa licenciar o uso de seu software, website e demais
            ativos de propriedade intelectual, fornecendo ferramentas para
            auxiliar e dinamizar o dia a dia dos seus usuários.
          </p>
          <p>A plataforma caracteriza-se pela prestação do seguinte serviço:</p>
          <p>Atendimento aos serviços prestados pela RNP e parceiros.</p>
          <h2>2. Da aceitação</h2>
          <p>
            O presente Termo estabelece obrigações contratadas de livre e
            espontânea vontade, por tempo indeterminado, entre a plataforma e as
            pessoas físicas ou jurídicas, usuárias do site.
          </p>
          <p>
            Ao utilizar a plataforma o usuário aceita integralmente as presentes
            normas e compromete-se a observá-las, sob o risco de aplicação das
            penalidades cabíveis.
          </p>
          <h2>3. Do acesso dos usuários</h2>
          <p>
            Serão utilizadas todas as soluções técnicas à disposição do
            responsável pela plataforma para permitir o acesso ao serviço 24
            (vinte e quatro) horas por dia, 7 (sete) dias por semana.
          </p>
          <h2>4. Do cadastro</h2>
          <p>
            O acesso às funcionalidades da plataforma exigirá a realização de um
            cadastro prévio.
          </p>
          <p>
            Após a confirmação do cadastro, o usuário possuirá um login e uma
            senha pessoal, a qual assegura ao usuário o acesso individual à
            mesma.
          </p>
          <h2>5. Do suporte</h2>
          <p>
            Em caso de qualquer dúvida, sugestão ou problema com a utilização da
            plataforma, o usuário poderá entrar em contato com o suporte.
          </p>
          <h2>6. Das responsabilidades</h2>
          <p>
            É de responsabilidade do usuário a correta utilização da plataforma,
            dos serviços ou produtos oferecidos.
          </p>
          <h2>7. Dos direitos autorais</h2>
          <p>
            O presente Termo de Uso concede aos usuários uma licença não
            exclusiva, não transferível e não sublicenciável, para acessar e
            fazer uso da plataforma e dos serviços e produtos por ela
            disponibilizados.
          </p>
          <h2>8. Das sanções</h2>
          <p>
            Sem prejuízo das demais medidas legais cabíveis, a plataforma pode,
            a qualquer momento, advertir, suspender ou cancelar a conta do
            usuário.
          </p>
          <h2>9. Da rescisão</h2>
          <p>
            A não observância das obrigações pactuadas neste Termo de Uso ou da
            legislação aplicável poderá, sem prévio aviso, ensejar a imediata
            rescisão unilateral por parte da plataforma e o bloqueio de todos os
            serviços prestados ao usuário.
          </p>
          <h2>10. Das alterações</h2>
          <p>
            Os itens descritos no presente instrumento poderão sofrer
            alterações, unilateralmente e a qualquer tempo, por parte da
            plataforma.
          </p>
          <h2>11. Da política de privacidade</h2>
          <p>
            Além do presente Termo, o usuário deverá consentir com as
            disposições contidas na respectiva Política de Privacidade a ser
            apresentada a todos os interessados dentro da interface da
            plataforma.
          </p>
          <h2>12. Do foro</h2>
          <p>
            Para a solução de controvérsias decorrentes do presente instrumento
            será aplicado integralmente o Direito brasileiro.
          </p>
        </div>
        <div className="flex flex-row-reverse mt-4">
          <ButtonCustom> <a href="/">Fechar popup</a></ButtonCustom>
        </div>
      </Card>
    </div>
  );
}

export default Terms;
