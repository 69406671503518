import React from 'react';

const Header = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto h-20 w-auto"
        src="https://res.cloudinary.com/dijid8gky/image/upload/v1673268602/IMG-RNP/Logo_eql5gy.png"
        alt='Rede Nacional de Ensino e Pesquisa'
      />
    </div>
  );
};

export default Header;
