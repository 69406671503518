import React, { useState } from "react";
import eyeOpen from "../../assets/eyeOpen.png";
import eyeClosed from "../../assets/eyeClosed.png";

function SquareInput({ value, placeholder, type, children, onChange, required }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <label className="mb-2 block text-sm font-medium leading-6 text-gray-900">
        {children}
      </label>
      <div className="relative">
        <input
          placeholder={placeholder}
          type={showPassword && type === "password" ? "text" : type}
          value={value}
          onChange={onChange}
          required={required}
          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />

        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-6 text-gray-600"
          >
            <img src={showPassword ? eyeClosed : eyeOpen} alt="Toggle visibility" className="h-5 w-5"/>
          </button>
        )}
      </div>
    </div>
  );
}

export default SquareInput;
